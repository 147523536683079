import React from "react"
import PropTypes from "prop-types"
import { FaFacebookF } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import { FaTwitter } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { FaRegEnvelope } from "react-icons/fa"
import { AiOutlineShoppingCart } from "react-icons/ai"
import { Link } from "gatsby"
import { RefugiosIcon } from "./../images/icono_pinta.png"
import { refugiosIcon } from "./../images/icono_pinta.png"

export default class Footer extends React.Component {
  static propTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    return (
      <div className={"footerWrapper"}>
        <div className={"footerSocialItem"}>
          <div className={"footerSocialInfo"}>
            <div>
              <div className={"footerIconCircle"}>
                <a
                  className={"footerLink"}
                  href="https://www.facebook.com/patagoniacerveza/"
                >
                  <div className={"footerSocialInfo"}>
                    <FaFacebookF />
                  </div>
                </a>
              </div>
              <span className={"footerSocialText"}>
                Danos like
                <br />
                en facebook
              </span>
            </div>
          </div>
        </div>
        <div className={"footerSocialItem lightGreen"}>
          <div className={"footerSocialInfo"}>
            <div>
              <div className={"footerIconCircle"}>
                <a
                  className={"footerLink"}
                  href={"https://www.instagram.com/cervezapatagonia/"}
                >
                  <div className={"footerSocialInfo"}>
                    <FaInstagram />
                  </div>
                </a>
              </div>
              <span className={"footerSocialText"}>
                unite a nuestro
                <br />
                instagram
              </span>
            </div>
          </div>
        </div>
        <div className={"footerSocialItem"}>
          <div className={"footerSocialInfo"}>
            <div>
              <div className={"footerIconCircle"}>
                <a href={"/refugios"} className={"footerLink"}>
                  <div className={"footerSocialInfo"}>
                    {/* <img src={refugiosIcon} alt="Refugios" /> */}
                    <div className={"refugiosIcon"}></div>
                  </div>
                </a>
              </div>
              <span className={"footerSocialText"}>
                Descubrí
                <br />
                nuestros Refugios{" "}
              </span>
            </div>
          </div>
        </div>
        <div className={"footerSocialItem lightGreen"}>
          <div className={"footerSocialInfo"}>
            <div>
              <div className={"footerIconCircle"}>
                <a
                  href={
                    "https://www.youtube.com/channel/UCkwAEzYPbHfzz9tXtOtj0cg"
                  }
                  className={"footerLink"}
                >
                  <div className={"footerSocialInfo"}>
                    <FaYoutube />
                  </div>
                </a>
              </div>
              <span className={"footerSocialText"}>
                visitanos
                <br />
                en youtube{" "}
              </span>
            </div>
          </div>
        </div>
        <div className={"footerSocialItem"}>
          <div className={"footerSocialInfo"}>
            <div>
              <div className={"footerIconCircle"}>
                <a
                  className={"footerLink"}
                  href="mailto: consultas@patagoniabrewingco.com"
                >
                  <div className={"footerSocialInfo"}>
                    <FaRegEnvelope />
                  </div>
                </a>
              </div>
              <span className={"footerSocialText"}>
                mandanos
                <br />
                un mensaje
              </span>
            </div>
          </div>
        </div>
        <div className={"footerSocialItem lightGreen"}>
          <div className={"footerSocialInfo"}>
            <div>
              <div className={"footerIconCircle"}>
                <a
                  className={"footerLink"}
                  href="https://www.craftsociety.com.ar/collections/patagonia-brewing-co"
                >
                  <div className={"footerSocialInfo"}>
                    <AiOutlineShoppingCart />
                  </div>
                </a>
              </div>
              <span className={"footerSocialText"}>
                conseguí
                <br />
                nuestas cervezas
              </span>
            </div>
          </div>
        </div>
        <div className={"footerTermsWrapper"}>
          <div className={"footerSocialInfo"}>
            <div>
              <a
                href={"../../docs/TyC.PDF"}
                className={"footerLink footerLinkText"}
              >
                Términos y condiciones
              </a>
              <span className={"footerLink footerLinkText footerLinkSeparator"}>
                |
              </span>
              <a
                href={"../../docs/POL.PDF"}
                className={"footerLink footerLinkText"}
              >
                Políticas de privacidad
              </a>
              <span className={"footerLink footerLinkText footerLinkSeparator"}>
                |
              </span>
              <a
                href={"mailto:comercial@patagoniabrewingco.com"}
                className={"footerLink footerLinkText"}
              >
                Franquicias
              </a>
              <span className={"footerLink footerLinkText footerLinkSeparator"}>
                |
              </span>
              <a
                href={
                  "https://www.tapintoyourbeer.com/agegate?destination=age_check.cfm"
                }
                className={"footerLink footerLinkText"}
              >
                tapintoyourbeer
              </a>
              <span className={"footerLink footerLinkText footerLinkSeparator"}>
                |
              </span>
              <a
                  href={
                    "https://www.cerveceriaymalteriaquilmes.com/"
                  }
                  className={"footerLink footerLinkText"}
              >
                INFORMACIÓN OFICIAL DE LA COMPAÑÍA
              </a>
              <div className={"footerMessage"}>
                BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS.
                NO COMPARTA EL CONTENIDO CON MENORES. ©2021 CERVEZA PATAGONIA
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
