import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { IoMdMenu } from "react-icons/io"
import { MdClose } from "react-icons/md"
import logoWhite from "../images/patagoniaWhite.png"
import logoDark from "../images/patagoniaDark.png"
import logoDarkLight from "../images/patagoniaDarkLight.png"
import Menu from "./Menu"
import classNames from 'classnames'
import MenuMobile from "./MenuMobile"
import ScrollArrow from "../images/scrollarrow.png"
import AnchorLink from "react-anchor-link-smooth-scroll"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"
import ScrollUpButton from "react-scroll-up-button"

const Header = ({
  isScrolling,
  onMenuToggle,
  isDark,
  isYellow,
  menuOpen,
  width,
  isHola,
  transparent,
  children,
}) => (
  <header
    className={"header"}    
      style={{
      marginBottom: `1.45rem`,
      position: "fixed",
      width: "100vw",
      zIndex: "10",
      height: "5vh",
      // background: 'linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6222864145658263) 20%, rgba(0,0,0,0) 100%);',
      opacity: isScrolling ? 0 : 1,
      dark: false,
      hola: false,
    }}
  >
    <div className={classNames({
      headerWrapper: true,
      transparent
    })}>
      <div className={"leftHeaderContainer"}>
        {!menuOpen && (
          <IoMdMenu onClick={onMenuToggle} className={"hamburgerMenuIcon"} />
        )}

        {width > 767 ? (
          menuOpen && (
            <MdClose
              onClick={onMenuToggle}
              className={"hamburgerMenuIcon xIcon"}
            />
          )
        ) : (
          <span></span>
        )}
      </div>
      {/*<Media>
        {({ breakpoints, currentBreakpoint }) =>
          breakpoints[currentBreakpoint] > breakpoints.tablet ? (
            menuOpen && <MdClose onClick={onMenuToggle} className={'hamburgerMenuIcon xIcon'}/>
          ) : (
            <span></span>
          )
        }
      </Media>*/}
      <div className={"middleHeaderContainer"}>
        <Link to={"/"}>
          {width > 767 ? (
            <img
              className={"headerLogo"}
              alt={"logo"}
              src={isDark ? logoDark : logoWhite}
            />
          ) : (
            <img
              className={"headerLogo"}
              alt={"logo"}
              src={isDark ? logoDarkLight : logoWhite}
            />
          )}
        </Link>
      </div>

      {/* {isHola && 
          <div className="navHomeTextContainer">
            <AnchorLink href="#nuestra-historia" className="aHomeText1"><h1 className="navHomeText1">Nuestra Historia</h1></AnchorLink>
            <AnchorLink href="#nuestro-manifiesto" className="aHomeText2"><h1 className="navHomeText2">Nuestro Manifiesto</h1></AnchorLink>
          </div>
          
      }
      {
        isHola &&
        <MobileView>
           <ScrollUpButton
            ContainerClassName="ScrollUpButton__Container" TransitionClassName="ScrollUpButton__Toggled"
            StopPosition={0}
            ShowAtPosition={150}
            EasingType='easeOutCubic'
            AnimationDuration={500}
            style={{}}
            ToggledStyle={{}}
          >
          <img src={ScrollArrow} alt="uparrow" class="arrowScrollBtn"/>
        </ScrollUpButton>
        </MobileView>
      } */}

      {/* <Media>
        {({ breakpoints, currentBreakpoint }) =>
          breakpoints[currentBreakpoint] > breakpoints.tablet ? (
            <img className={'headerLogo'} alt={'logo'} src={isDark ? logoDark : logoWhite}/>
          ) : (
            <img className={'headerLogo'} alt={'logo'} src={isDark ? logoDarkLight : logoWhite}/>
          )
        }
      </Media> */}

      {/* <h1 style={{ margin: 0 }}>1
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>*/}


      {children && (
        <div className="rightHeaderContainer">
          {React.Children.map(children, child => (child.props.goTo ?
            <AnchorLink href={child.props.goTo} className="headerLink">
              <h1 className="rightHeaderLink">{child.props.text}</h1>
            </AnchorLink>
            :
            <div className="headerLink" onClick={child.props.click}>
             <h1 className="rightHeaderLink">{child.props.text}</h1>
            </div>
          ))}
        </div>
      )}

      {/* Esto es para mostrar una arrow en la parte de abajo que sirve para ir al header */}
      <MobileView>
        <ScrollUpButton
          ContainerClassName="ScrollUpButton__Container"
          TransitionClassName="ScrollUpButton__Toggled"
          StopPosition={0}
          ShowAtPosition={150}
          EasingType="easeOutCubic"
          AnimationDuration={500}
          style={{}}
          ToggledStyle={{}}
        >
          <img  alt="" src={ScrollArrow} alt="uparrow" class="arrowScrollBtn" />
        </ScrollUpButton>
      </MobileView>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
