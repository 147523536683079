import React from "react"
import PropTypes from "prop-types"
import { MdClose } from "react-icons/md";

import lineFolded from '../images/menuItemFoldedLine.png'
import lineExpanded from '../images/menuItemExpandedLine.png'
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { Link } from "gatsby"

export default class MenuMobile extends React.Component {

  static propTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      open: '',
      hover1: false,
      hover2: false,
      hover3: false,
      hover4: false,
      hover5: false,
      hover6: false,
      hover7: false,
      hover8: false,
      hover9: false
    }
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className={'menuWrapper menuMobileWrapper ' + this.props.menuClass}>
        <div className={'menuMobileItemsWrapper'}>
          <Link to={'/nosotros'} className={'menuMobileItem'}>
            <span>NOSOTROS</span>
          </Link>
          <Link to={'/nuestrascervezas'} className={'menuMobileItem'}>
            <span>NUESTRAS CERVEZAS</span>
          </Link>
          <Link to={'/gincomunal'} className={'menuMobileItem'}>
            <span>gin comunal</span>
          </Link>
          <Link to={'/isidra'} className={'menuMobileItem'}>
            <span>isidra</span>
          </Link>
          <Link to={'/microcerveceria'} className={'menuMobileItem'}>
            <span>microcervecería</span>
          </Link>
          {/* <div className={'menuMobileItem'}>
            <span>conocimiento cervecero</span>
          </div>*/}
          <Link to={'/refugios'} className={'menuMobileItem'}>
            <span>refugios</span>
          </Link>

          <Link to={'/nuestroproposito'} className={'menuMobileItem'}>
            <span>Nuestro Propósito</span>
          </Link>

          {/*<div className={'menuMobileItem'}>
            <span>sustentabilidad</span>
          </div>*/}
          <a href={'https://www.craftsociety.com.ar/collections/patagonia-brewing-co'} className={'menuMobileItem'}>
            <span>shop</span>
          </a>
          <div className={'menuMobileX'}>
            <MdClose onClick={this.props.onMenuToggle} className={'xIcon'} />
          </div>
        </div>
      </div>
    )
  }
}